import {useBackgroundStyleConfigs} from 'hooks/useBackgroundStyleConfigs';
import React from 'react';

import {SxProps} from '@mui/material';

import {SubcomponentsButtonComponent} from 'lib/api/strapi';

import {Box, Button} from 'components/basic-components';

import {CustomIcon} from '../CustomIcon/CustomIcon';
import {NextLink} from '../NextLink/Link';
import {ExtendedButtonProps} from './Button';
import styles from './ButtonWithConfig.module.scss';

export const ButtonWithConfig = ({
  buttonProps,
  variant,
  useThemeStyle,
  withoutStyles = false,
  sx,
}: {
  buttonProps?: SubcomponentsButtonComponent;
  variant?: ExtendedButtonProps['variant'];
  useThemeStyle?: boolean;
  withoutStyles?: boolean;
  sx?: SxProps;
}) => {
  const {
    buttonStyle: {variant: themeVariant},
  } = useBackgroundStyleConfigs();
  const href = !!buttonProps?.pdf?.data?.attributes?.url
    ? buttonProps?.pdf?.data?.attributes?.url
    : buttonProps?.page?.data?.attributes?.fullSlug
    ? `/${buttonProps?.page?.data?.attributes?.fullSlug}`
    : buttonProps?.buttonLink
    ? buttonProps?.buttonLink
    : undefined;

  if (!buttonProps) {
    return null;
  }
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent:
          buttonProps?.buttonPosition === 'left'
            ? 'flex-start'
            : buttonProps?.buttonPosition === 'right'
            ? 'flex-end'
            : 'center',
      }}
    >
      <Button
        sx={{padding: href ? 0 : null, ...sx}}
        className={withoutStyles ? null : styles.button}
        variant={useThemeStyle ? themeVariant : variant}
      >
        {href ? (
          <NextLink
            href={href}
            style={{
              textDecoration: 'none',
              color: 'inherit',
              padding: '13px 20px',
              display: 'block',
            }}
            target={
              buttonProps?.newWindow || buttonProps?.pdf?.data?.attributes?.url ? '_blank' : '_self'
            }
          >
            {buttonProps?.icon ? (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection:
                    buttonProps?.iconPosition === 'start'
                      ? 'row'
                      : buttonProps?.iconPosition === 'end'
                      ? 'row-reverse'
                      : null,
                }}
              >
                <CustomIcon
                  name={buttonProps?.icon}
                  style={{
                    marginRight: buttonProps?.iconPosition === 'start' ? '5px' : 0,
                    marginLeft: buttonProps?.iconPosition === 'end' ? '5px' : 0,
                    height: '24px',
                    width: '24px',
                  }}
                />
                {buttonProps?.buttonText}
              </Box>
            ) : (
              buttonProps?.buttonText
            )}
          </NextLink>
        ) : (
          <>
            {buttonProps?.icon ? (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection:
                    buttonProps?.iconPosition === 'start'
                      ? 'row'
                      : buttonProps?.iconPosition === 'end'
                      ? 'row-reverse'
                      : null,
                }}
              >
                <CustomIcon
                  name={buttonProps?.icon}
                  style={{
                    marginRight: buttonProps?.iconPosition === 'start' ? '5px' : 0,
                    marginLeft: buttonProps?.iconPosition === 'end' ? '5px' : 0,
                    height: '24px',
                    width: '24px',
                  }}
                />
                {buttonProps?.buttonText}
              </Box>
            ) : (
              buttonProps?.buttonText
            )}
          </>
        )}
      </Button>
    </Box>
  );
};
